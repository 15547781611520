.Admin {
    padding: 2rem;
    background-color: $element-background;
    min-height: 85vh;
}

.Home-container {
    padding-top: 4vh;
}

.Detail-container {
    padding-top: 2vh;
}

.Detail-container-section {
    border-bottom: 1px solid $border;
    padding-bottom: 2rem;
}

.Detail-images {
    overflow: hidden;
}

.Detail-images-action {
    border-radius: $border-radius;
}

.Detail-images-big {
    object-fit: cover;
    width: 100%;
    border-radius: $border-radius;
    height: 414px;
}

.Detail-images-small {
    height: 200px;
    object-fit: cover;
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid $border;
}

.Detail-widget {
    border: 1px solid $border;
    border-radius: $border-radius;
    background-color: $element-background;
    width: 100%;
    height: 90rem;
}

.Detail-map-container {
    height: 40vh;
}